import { combineReducers } from '@reduxjs/toolkit';

// import sub reducers from features folder
import blockchainReducer from '../features/blockchain/blockchainSlice';
import configReducer from '../features/config/configSlice';

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  config: configReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
