import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import { increment, decrement, connectBlockchain, getTotalSupply, getPrice, mintNFTs } from '../features/blockchain/blockchainSlice';
import { getConfig } from '../features/config/configSlice';
import { Screen, Container, ResponsiveWrapper, StyledImg, Subtitle, StyledP, Heading, RightContainer, LeftContainer, TitleImg, TitleWrapper, StyledIcon, MediaWrapper, ResponsiveSpacerLarge } from './App.styles';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { store } from './store';
import { Blockchain } from '../features/blockchain/Blockchain';
import { Link } from '@mui/material';

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: RootState) => state,
  );

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    if (state.blockchain.smartContract) {
      dispatch(getTotalSupply(state.blockchain.smartContract));
      dispatch(getPrice(state.blockchain.smartContract));
    }
  }, [dispatch, state.blockchain.smartContract]);

  const handleConnectBlockchain: React.MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(connectBlockchain(state.config));
  }

  const handleIncrement: React.MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(increment());
  }

  const handleDecrement: React.MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(decrement());
  }

  const handleMintNFTsClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (state.blockchain.smartContract) {
      dispatch(mintNFTs(state.blockchain.smartContract, {
        from: state.blockchain.account,
        to: state.config.contractAddress,
        gasLimit: state.config.gasLimit,
        mintAmount: state.blockchain.mintAmount,
      }));
    }
  }

  console.log(state.blockchain);
  return (
    <Screen>
      <Container
        flex={1}
        alignItems={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={"/config/images/bg.png"}
      >
        <Container flex={1} justifyContent={"center"} alignItems={"center"}
          style={{
          }}
        >
          <TitleWrapper>
            <TitleImg alt={"example"} src={"/config/images/btchplease_master.jpg"} />
            <Heading>btchpleasemfers</Heading>
          </TitleWrapper>
            
          <MediaWrapper>
            <Link href={state.config.twitterLink}>
              <StyledIcon alt={"twitter"} src={"/config/images/twitter_icons.png"} />
            </Link>
            <Link href={state.config.marketplaceLink}>
              <StyledIcon alt={"opensea"} src={"/config/images/opensea_icons.png"} />
            </Link>
            <Link href={state.config.scanLink}>
              <StyledIcon alt={"etherscan"} src={"/config/images/etherscan_icons.png"} />
            </Link>
          </MediaWrapper>

          <Blockchain
            onClickBuy={handleMintNFTsClick}
            onClickConnect={handleConnectBlockchain}
            onClickIncrement={handleIncrement}
            onClickDecrement={handleDecrement}
            blockchain={state.blockchain}
            config={state.config}
          />
          <ResponsiveWrapper style={{ marginTop: 50, marginBottom: 50 }}>
            <LeftContainer style={{ marginTop: 10 }}>
              <StyledImg alt={"btchpleasemfers1"} src={"/config/images/btchplease1.gif"} />
              <ResponsiveSpacerLarge />
              <StyledImg alt={"btchpleasemfers2"} src={"/config/images/btchplease2.gif"} />
            </LeftContainer>

            <RightContainer>
              <Subtitle>
                btch please
              </Subtitle>
              <StyledP>
                let's just make another mfers
              </StyledP>
              <StyledP>
                enough of hype, bullsh*t and road to nowhere map.
              </StyledP>
              <StyledP>
                we are the purest of pure, another @sartoshi's bastard son
              </StyledP>
              <StyledP>
                so, no official dischord, no roadmap, no sh*t given
              </StyledP>
              <StyledP>
                but we got this web & twitter
              </StyledP>
              <StyledP>
                just so you know where to find us
              </StyledP>
              <ResponsiveSpacerLarge />
              <StyledP>
                got sick with todays nfts ? feel robbed ? cheated ?
              </StyledP>
              <Subtitle>
                btch please
              </Subtitle>
              <StyledP>
                we are still early they say.
              </StyledP>
              <StyledP>
                cheers mfer
              </StyledP>
              <StyledP>
                we love you
              </StyledP>
              
            </RightContainer>
          </ResponsiveWrapper>
        </Container>
      </Container>
    </Screen>
  );
}

const WrappedApp = (): JSX.Element => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>)
  ;
}

export default WrappedApp;
