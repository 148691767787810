// constants
import Web3EthContract, { Contract } from "web3-eth-contract";
import Web3 from "web3";

import { ConfigState } from "../config/configSlice";
import { AbiItem } from "web3-utils";
import abi from '../../config/abi.json';

export type MintNftApiParams = {
    totalCost: number;
    mintAmount: number;
    gasLimit: number;
    to: string;
    from: string;
}

export type BlockchainInitData = {
    totalSupply: number;
    totalFreeSupply: number;
    totalFreeMintedQty: number;
    maxSupply: number;
}


export const fetchSmartContract = async (config: ConfigState): Promise<Contract> => {
    const { ethereum } = window as any;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (!metamaskIsInstalled) {
        throw Error("Install Metamask");
    }
    (Web3EthContract as any).setProvider(ethereum);
    const web3 = new Web3(ethereum);
    const networkId = await ethereum.request({
        method: "net_version",
    });
    if (networkId != config.network.id) {
        throw Error(`Change network to ${config.network.name}.`);
    }

    const smartContract: Contract = new web3.eth.Contract(
        abi as AbiItem[],
        config.contractAddress
    );
    return smartContract
};

export const fetchAccount = async (): Promise<string> => {
    const { ethereum } = window as any;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (!metamaskIsInstalled) {
        throw Error("Install Metamask");
    }
    const accounts: string[] = await ethereum.request({
        method: "eth_requestAccounts",
    });
    return accounts[0];
}

export const fetchTotalSupply = async (smartContract: Contract): Promise<number> => {
    const totalSupply = await smartContract.methods.totalSupply().call();
    return parseInt(totalSupply);
}

export const fetchPrice = async (smartContract: Contract): Promise<number> => {
    const price = await smartContract.methods.getPrice().call();
    return parseInt(price);
}

export const fetchData = async (smartContract: Contract): Promise<BlockchainInitData> => {
    const totalSupply = await smartContract.methods.totalSupply().call();
    const totalFreeSupply = await smartContract.methods.freeMaxSupply().call();
    const totalFreeMintedQty = await smartContract.methods.totalFreeMintedQty().call();
    const maxSupply = await smartContract.methods.maxSupply().call();
    return {
        totalSupply,
        totalFreeSupply,
        totalFreeMintedQty,
        maxSupply
    };
}

export const mintNFTs = async (smartContract: Contract, {totalCost, mintAmount, gasLimit, to, from}: MintNftApiParams): Promise<void> => {
    const totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCost);
    console.log("Gas limit: ", totalGasLimit);
    const result = await smartContract.methods
        .mint(mintAmount)
        .send({
            gasLimit: String(totalGasLimit),
            to: to,
            from: from,
            value: String(totalCost),
        })
    return result;  
}

export const calculateTotalCost = async (smartContract: Contract, account: string, mintAmount: number): Promise<number> => {
    const totalCost = await smartContract.methods.getTotalMintPrice(account, mintAmount).call();
    return parseInt(totalCost);
}