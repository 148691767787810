import styled from '@emotion/styled';

type ScreenProps = {
    image?: string;
}

type ContainerProps = {
    flex?: number;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    image?: string;
}


// Used for wrapping a page component
export const Screen = styled.div<ScreenProps>`
    background-color: var(--primary);
    background-image: ${({ image }) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;


// Used for providing space between components
export const SpacerXSmall = styled.div`
    height: 8px;
    width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
    height: 16px;
    width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
    height: 24px;
    width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
    height: 32px;
    width: 32px;
`;

export const ResponsiveSpacerLarge = styled.div`
  height: 32px;
  width: 32px;
  @media (max-width: 840px) {
    height: 16px;
    width: 16px;
  }
  @media (max-width: 640px) {
    height: 8px;
    width: 8px;
  }
`

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ flexDirection }) => (flexDirection ?? "column")};
    justify-content: ${({ justifyContent }) => (justifyContent ?? "center")};
    align-items: ${({ alignItems }) => (alignItems ?? "center")};
    width: 100%;
    background-image: ${({ image }) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
`;


export const BlockchainContainer = styled.div<ContainerProps>`
    display: flex;
    flex: ${({ flex }) => (flex ? flex : 0)};
    flex-direction: ${({ flexDirection }) => (flexDirection ?? "column")};
    justify-content: ${({ justifyContent }) => (justifyContent ?? "center")};
    align-items: ${({ alignItems }) => (alignItems ?? "center")};
    width: 100%;
    background-image: ${({ image }) => (image ? `url(${image})` : "none")};
    background-size: cover;
    background-position: center;
    color: var(--mintbox-text);
`;


export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 95%;
  margin-left: 40px;
  @media (max-width: 480px) {
    margin-left: 0;
    width: 80%
    justify-content: center;
    align-items: center;
    display: flex;
}
`;

export const TextTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 0.5;

  @media (max-width: 480px) {
    font-size: 1em;
    line-height: 1;
  }
`;

export const TextDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const MediaWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 10px;
  border: none;
  background-color: var(--mintbox-button);
  font-weight: 700;
  font-size: 18px;
  color: var(--secondary-text);
  width: 145px;
  cursor: pointer;

  @media (max-width: 480px) {
    padding: 8px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
  }
`;

export const StyledRoundButton = styled.button`
  border-radius: 10px;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  color: var(--primary-text);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 5px;
    border-radius: 7px;
    font-weight: 600;
    font-size: 15px;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitleImg = styled.img`
  background-color: var(--accent);
  width: 93px;
  transition: width 0.5s;
  @media (max-width: 480px) {
    width: 60px;
  }
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  width: 600px;
  @media (max-width: 840px) {
    width: 480px;
  }
  @media (max-width: 640px) {
    width: 80%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const Heading = styled.h3`
  font-family: Anton;
  font-weight: 200;
  font-size: 40px;
  color: #414040;
  margin: 0 30px;
  @media (max-width: 480px) {
    font-size: 10vw;
  }
`

export const Subtitle = styled.p`
  font-family: Helvetica;
  font-weight: bold;
  font-size: 28px;
  color: #414040;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  @media (max-width: 480px) {
    font-weight: 600;
    font-size: 26px;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
    width: 80%;
    margin-top: 20px;
  }
`

export const StyledP = styled.p`
  font-family: Assistant, sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #414040;
  margin-block-start: 0.8em;
  margin-block-end: 0.8em;
  line-height: 1.1;
  @media (max-width: 480px) {
    font-weight: 600;
    font-size: 15px;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
    width: 80%;
  }
`

export const StyledIcon = styled.img`
  background-color: var(--primary);
  width: 50px;
  transition: width 0.5s;
  margin-right: 10px;
  @media (max-width: 480px) {
    width: 30px;
  }
`;