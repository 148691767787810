import { ConfigState } from "./configSlice";

export const fetchConfig = async (): Promise<ConfigState> => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return configResponse.json();
    
};
