import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { fetchConfig } from './ConfigApi';

export interface ConfigState {
    contractAddress: string;
    scanLink: string;
    network: {
        name: string;
        symbol: string;
        id: number;
    },
    nftName: string;
    symbol: string;
    maxSupply: number;
    displayCost: number;
    gasLimit: number;
    marketplace: string;
    marketplaceLink: string;
    maxFreeSupply: number;
    maxFreePerWallet: number,
    twitterLink: string;
    maxPerTx: number;
}

const initialState: ConfigState = {
    contractAddress: "",
    scanLink: "",
    network: {
        name: "",
        symbol: "",
        id: 0,
    },
    nftName: "",
    symbol: "",
    maxSupply: 0,
    displayCost: 0,
    gasLimit: 0,
    marketplace: "",
    marketplaceLink: "",
    maxFreeSupply: 0,
    maxFreePerWallet: 3,
    twitterLink: "",
    maxPerTx: 0,
}; 

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig(_, { payload }: PayloadAction<ConfigState>): ConfigState {
            return payload;
        }
    }
});

export const { setConfig } = configSlice.actions;
export default configSlice.reducer;

// Thunk Section Below

export const getConfig = (): AppThunk => async (dispatch): Promise<void> => {
    const result = await fetchConfig();
    dispatch(setConfig(result));
};
