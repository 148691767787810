import { createTheme, Theme } from "@mui/material/styles";

const theme = (): Theme =>
  createTheme({
    typography: {
      fontFamily: ['Assistant'].join(','),
      h1: { fontSize: 96, lineHeight: 1, letterSpacing: -0.66 },
      h2: { fontSize: 60, lineHeight: 1, letterSpacing: -0.5 },
      h3: { fontSize: 48, lineHeight: 1, letterSpacing: 0 },
      h4: { fontSize: 34, lineHeight: 1.147, letterSpacing: 0.07 },
      h5: { fontSize: 24, lineHeight: 1.33, letterSpacing: 0 },
      h6: { fontSize: 20, lineHeight: 1.6, letterSpacing: 0.07, fontWeight: 400 },
      subtitle1: { fontSize: 16, lineHeight: 1.75, letterSpacing: 0.09 },
      subtitle2: { fontSize: 14, lineHeight: 1.57, letterSpacing: 0.07 },
      body1: { fontSize: 16, lineHeight: 1.5, letterSpacing: 0.09 },
      body2: { fontSize: 14, lineHeight: 1.429, letterSpacing: 0.1 },
      caption: { fontSize: 12, lineHeight: 1.66, letterSpacing: 0.3 },
      overline: { fontSize: 12, lineHeight: 2.583, letterSpacing: 0.83 },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1025,
        xl: 1920,
      },
    },
  });

export default theme();