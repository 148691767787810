import { BlockchainContainer, Container, SpacerMedium, SpacerSmall, StyledButton, StyledLink, StyledRoundButton, TextDescription, TextTitle } from '../../app/App.styles';
import { ConfigState } from '../config/configSlice';
import { BlockchainState } from './blockchainSlice';

export interface BlockchainProps {
    onClickConnect: React.MouseEventHandler<HTMLButtonElement>;
    onClickIncrement: React.MouseEventHandler<HTMLButtonElement>;
    onClickDecrement: React.MouseEventHandler<HTMLButtonElement>;
    onClickBuy: React.MouseEventHandler<HTMLButtonElement>;
    blockchain: BlockchainState;
    config: ConfigState;
}

export const Blockchain = (props: BlockchainProps): JSX.Element => {
    const { onClickConnect, onClickIncrement, onClickDecrement, onClickBuy, blockchain, config } = props;
    return (
        <>
        <BlockchainContainer
          flex={1}
          style={{backgroundColor: "var(--mintbox-background)"}}
        >
          <TextTitle style={{fontWeight: 700, fontSize: 22}}>
            Minted: {blockchain.totalSupply} / {blockchain.maxSupply}
          </TextTitle>
          {Number(blockchain.totalSupply) >= blockchain.maxSupply && blockchain.maxSupply > 0 ? (
            <>
              <TextTitle>
                The sale has ended.
              </TextTitle>
              <TextDescription>
                You can still find {config.nftName} on
              </TextDescription>
              <SpacerSmall />
              <StyledLink target={"_blank"} href={config.marketplaceLink}>
                {config.marketplace}
              </StyledLink>
            </>
          ) : (
            <>
              <TextTitle style={{fontWeight: 700, fontSize: 22}}>
                Free Mint: {blockchain.totalFreeMintedQty} / {blockchain.totalFreeSupply}
              </TextTitle>
              <TextTitle>
                {config.displayCost} each, Max Free {config.maxFreePerWallet} NFTs / wallet
              </TextTitle>
              <TextTitle>
                Max Mint {config.maxPerTx} NFTs / transaction
              </TextTitle>
              <SpacerSmall />
              { blockchain.account === "" || !blockchain.smartContract ? (
                <Container alignItems={"center"} justifyContent={"center"}>
                  <StyledButton onClick={onClickConnect}>
                    CONNECT
                  </StyledButton>
                  {blockchain.errorMessage !== "" ? (
                    <>
                      <SpacerSmall />
                      <TextDescription
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {blockchain.errorMessage}
                      </TextDescription>
                    </>
                  ) : null}
                </Container>
              ) : (
                <>
                  <Container alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
                    <StyledRoundButton
                      style={{ backgroundColor: "var(--mintbox-button)" }}
                      disabled={blockchain.claimingNFT}
                      onClick={onClickDecrement}
                    >
                      -
                    </StyledRoundButton>
                    <SpacerMedium />
                    <TextDescription
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {blockchain.mintAmount}
                    </TextDescription>
                    <SpacerMedium />
                    <StyledRoundButton
                      style={{ backgroundColor: "var(--mintbox-button)" }}
                      disabled={blockchain.claimingNFT}
                      onClick={onClickIncrement}
                    >
                      +
                    </StyledRoundButton>
                  </Container>
                  <SpacerSmall />
                  <Container alignItems={"center"} justifyContent={"center"} flexDirection={"row"}>
                    <StyledButton
                      disabled={blockchain.claimingNFT || blockchain.mintAmount <= 0}
                      onClick={onClickBuy}
                    >
                      {blockchain.claimingNFT ? "MINTING..." : "BUY"}
                    </StyledButton>
                  </Container>
                  <TextDescription
                    style={{
                      textAlign: "center"
                    }}
                  >
                    {blockchain.feedback}
                  </TextDescription>
                  <TextDescription
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: 250
                    }}
                  >
                    Your wallet address: {blockchain.maskedAccount}
                  </TextDescription>
                </>
              )}
            </>
          )}
          <SpacerMedium />
        </BlockchainContainer>
        </>
    )
}