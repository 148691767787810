import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract } from 'web3-eth-contract';
import { AppThunk } from '../../app/store';
import { ConfigState } from '../config/configSlice';
import * as blockchainApi from './blockchainApi';

export interface BlockchainState {
    mintAmount: number;
    account: string;
    smartContract?: Contract;
    errorMessage: string;
    feedback: string;
    claimingNFT: boolean;
    price: number;
    maskedAccount: string;
    totalSupply: number;
    totalFreeSupply: number;
    totalFreeMintedQty: number;
    maxSupply: number;
}

const initialState: BlockchainState = {
    mintAmount: 0,
    account: "",
    errorMessage: "",
    feedback: "Click buy to mint your NFT.",
    claimingNFT: false,
    price: 0,
    maskedAccount: "",
    totalSupply: 0,
    totalFreeSupply: 0,
    totalFreeMintedQty: 0,
    maxSupply: 0
};

export type MintNftParams = {
    mintAmount: number;
    gasLimit: number;
    to: string;
    from: string;
}

const blockchainSlice = createSlice({
    name: 'blockchain',
    initialState,
    reducers: {
        connectSuccess: (state, { payload }: PayloadAction<Contract>): BlockchainState => {
            return {
                ...state,
                smartContract: payload,
                errorMessage: "",
                feedback: ""
            }
        },
        increment: (state) => {
            state.mintAmount++;
        },
        decrement: (state) => {
            if (state.mintAmount > 0) state.mintAmount--;
        },
        setAccount: (state, { payload }: PayloadAction<string>) => {
            if (payload) {
                state.account = payload;
                const firstFour = payload.substring(0,4);
                const lastFour = payload.substring(payload.length-4, payload.length);
                state.maskedAccount = `${firstFour}...${lastFour}`;
            } else {
                state.account = '';
                state.maskedAccount = '';
            }
        },
        setTotalSupply: (state, { payload }: PayloadAction<number>) => {
            state.totalSupply = payload;
        },
        setErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorMessage = payload;
        },
        setFeedback: (state, { payload }: PayloadAction<string>) => {
            state.feedback = payload;
        },
        setClaimingNFT: (state, { payload }: PayloadAction<boolean>) => {
            state.claimingNFT = payload;
        },
        setPrice: (state, { payload }: PayloadAction<number>) => {
            state.price = payload;
        },
        setData: (state, { payload }: PayloadAction<blockchainApi.BlockchainInitData>) => {
            state.totalSupply = payload.totalSupply;
            state.totalFreeSupply = payload.totalFreeSupply;
            state.maxSupply = payload.maxSupply;
            state.totalFreeMintedQty = payload.totalFreeMintedQty;
        },
    }
});

export const { connectSuccess, increment, decrement, setAccount, setTotalSupply, setErrorMessage, setFeedback, setClaimingNFT, setPrice, setData } = blockchainSlice.actions;
export default blockchainSlice.reducer;

// Thunk Section Below

export const connectBlockchain = (config: ConfigState): AppThunk => async (dispatch): Promise<void> => {
    try {
        const smartContract = await blockchainApi.fetchSmartContract(config);
        dispatch(connectSuccess(smartContract))

        const account = await blockchainApi.fetchAccount();
        dispatch(setAccount(account));

        const data = await blockchainApi.fetchData(smartContract);
        dispatch(setData(data));

        const { ethereum } = window as any;
        ethereum.on("accountsChanged", (accounts: string[]) => {
            dispatch(setAccount(accounts[0]));
        });
        ethereum.on("chainChanged", () => {
            window.location.reload();
        });

    } catch (err: any) {
        console.log(err);
        dispatch(setErrorMessage(err.message));
    }
};

export const getTotalSupply = (smartContract: Contract): AppThunk => async (dispatch): Promise<void> => {
    const totalSupply = await blockchainApi.fetchTotalSupply(smartContract);
    dispatch(setTotalSupply(totalSupply));
}

export const getPrice = (smartContract: Contract): AppThunk => async (dispatch): Promise<void> => {
    const price = await blockchainApi.fetchPrice(smartContract);
    dispatch(setPrice(price));
}

export const mintNFTs = (smartContract: Contract, params: MintNftParams): AppThunk => async (dispatch): Promise<void> => {
    try {
        dispatch(setClaimingNFT(true));
        const totalCost = await blockchainApi.calculateTotalCost(smartContract, params.from, params.mintAmount);

        const result = await blockchainApi.mintNFTs(smartContract, {
            totalCost,
            ...params
        });
        console.log(result);
        dispatch(setClaimingNFT(false));
        dispatch(setFeedback(`The NFT is yours! Go visit opensea.io to view it`));
        
        const data = await blockchainApi.fetchData(smartContract);
        dispatch(setData(data));
    } catch (err: any) {
        console.log(err);
        dispatch(setFeedback("Sorry, something went wrong please try again later."));
        dispatch(setClaimingNFT(false));
    }
}